.MuiStepper-root {
  .MuiSvgIcon-root {
    color: $light;
    &.Mui-active{
      color: $primary;
    }
  }

  .MuiStepLabel-label {
    color: $light;

    &.Mui-active {
      color: $primary;
    }
  }

  @media(max-width: 991px) {
    .label-sm-hidden {

    }
  }
}

