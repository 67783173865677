:root {
  --picklejar-primary: #FF9E18;
  --picklejar-primary-disabled: #rgba (255, 153, 0, 0.77);
  --picklejar-secondary: #66C22B;
  --picklejar-gradient-primary: linear-gradient(90deg, rgba(246,145,30,1) 0%, rgba(195,76,39,1) 100%);
  --picklejar-light: #fff;
  --picklejar-light-opacity: rgba(255, 255, 255, .75);
  --picklejar-dark: #000;
  --picklejar-dark-opacity: rgba(0, 0, 0, .75);
  --picklejar-border-color: #dedede;
  --picklejar-border-color-dark: #323232;
  --picklejar-link: var(--picklejar-primary);
  --picklejar-bg-light: var(--picklejar-light);
  --picklejar-bg-dark: var(--picklejar-dark);
  --picklejar-text-color-light: var(--picklejar-light);
  --picklejar-text-color-dark: var(--picklejar-dark);
  --picklejar-input-border-color-primary: var(--picklejar-light);
  --picklejar-input-border-color-secondary: var(--picklejar-light);
  --picklejar-placeholder-color: var(--picklejar-light);
  --picklejar-accordion-border-radius: 24px;

  --picklejar-success: #69C16C;
  --picklejar-info: #1FA9FF;
  --picklejar-warning: #FF9D00;
  --picklejar-danger: #DE3535;
  --picklejar-icon-button: 64px;

  --picklejar-paragraph: 16px;
  --picklejar-h1: 3rem;
  --picklejar-h2: 2.8em;
  --picklejar-h3: 2.2em;
  --picklejar-h4: 1.5rem;
  --picklejar-h5: 1.25rem;
  --picklejar-h6: 1.125rem;
  --picklejar-font-size-large: clamp(1.15rem, 2vw, 1.25rem);

}

//Breakpoints
$xs: 0px;
$xsMax: 559px;
$sm: 600px;
$smMax: 889px;
$md: 900px;
$mdMax: 1199px;
$lg: 1200px;
$lgMax: 1535px;
$xl: 1536px;

//Colors
$primary: #FF9900;
$primary-disabled: rgba(255, 153, 0, 0.77);
$secondary: #00ff00;
$light: #fff;
$light-opacity: rgba(255, 255, 255, .75);
$dark: #000;
$dark-opacity: rgba(0, 0, 0, .75);
$border-color-dark: #323232;
$link: $primary;

//Backgrounds
$bg-light: $light;
$bg-dark: $dark;

//Text Colors
$text-color-light: $light;
$text-color-dark: $dark;

//Inputs
$input-border-color-primary: #fff;
$input-border-color-secondary: #fff;
$placeholder-color: #fff;

