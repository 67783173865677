.bg-success {
  background-color: var(--picklejar-success);
  color: var(--picklejar-light);
}

.bg-info {
  background-color: var(--picklejar-info);
  color: var(--picklejar-light);
}

.bg-warning {
  background-color: var(--picklejar-warning);
  color: var(--picklejar-light);
}

.bg-danger {
  background-color: var(--picklejar-danger);
  color: var(--picklejar-light);
}
