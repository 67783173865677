.ClaimsBody {
  padding-bottom: 1.5rem;
  padding-top: 0;
}

.ClaimsSidebar {
  height: 100%;
  max-height: 100vh;

  padding: clamp(45px, 3vw, 80px) 40px;
  top: 0;
  bottom: 0;
  position: sticky;
  display: flex;
  align-content: center;
  background-color: #000;
  overflow: hidden;
  @supports (-webkit-touch-callout: none) {
    height: auto;
    /* CSS specific to iOS devices */
    min-height: -webkit-fill-available;
  }

  img {
    height: auto;
  }
}

.pj-layout-wrapper {

  .wrapper__breadcrumb {
    margin: 0;
    padding: 16px;
    display: flex;
    font-size: 18px;

    .breadcrumb__item {
      display: flex;
      align-items: center;

      &::before {
        margin-right: 8px;
        margin-left: 8px;
        display: block;
        font-size: 20px;
        content: "/";
      }

      &:first-child::before {
        display: none;
      }
    }
  }

  .pj-body-wrapper {
    height: 100%;

    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }

    @supports not (-webkit-touch-callout: none) {
      @media(min-width: $md) {
        max-height: calc(100vh - 80px);
      }
    }

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: #EFEFEF;
    overflow: auto;
  }

  .pj-content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    > div {
      flex-grow: 1;
    }
  }
}

.pj-app-bar {
  border-bottom: solid 2px var(--picklejar-primary, $primary);
  @media(max-width: $smMax) {
    top: 0;
    position: sticky !important;
    z-index: 5;
  }

  .pj-brand-logo {
    height: 65px;
    width: 50px;
    margin-right: 1rem;
  }
}

@media (max-width: 576px) {
  .pj-layout-wrapper {
    .wrapper__breadcrumb {
      padding: 16px 16px 0 16px;

      .breadcrumb__item {
        margin: 2px 0;

        &::before {
          margin-right: 4px;
          margin-left: 0;
          font-size: 14px;
        }

        &:nth-child(2) {
          margin-left: 6px;
        }

        &:nth-child(3) {
          margin-left: 12px;
        }

        &:nth-child(4) {
          margin-left: 18px;
        }

        &:nth-child(5) {
          margin-left: 24px;
        }

        &:nth-child(6) {
          margin-left: 30px;
        }

        &:nth-child(7) {
          margin-left: 36px;
        }
      }
    }
  }

  .ClaimsSidebar {
    height: auto;
    display: none;
  }
}
