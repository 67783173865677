form {
  width: 100%;
}

::placeholder {
  color: $placeholder-color;
  opacity: 0.8;
}

:-ms-input-placeholder {
  color: $placeholder-color;
}

::-ms-input-placeholder {
  color: $placeholder-color;
}

.loading-overlay {
  width: 100%;
  max-width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);

  &.hidden {
    display: none;
  }
}

.MuiInput-root {
  &.text-area,
  &.image-crop-input {
    .MuiInputLabel-standard.MuiInputLabel-root {
      opacity: 1;
    }
  }
}

.text-area,
.image-crop-input {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 0.5rem;
    text-align: left;
  }

  textarea {
    padding-right: 12px;
    padding-left: 12px;
    border: none;
    background: rgba(255, 255, 255, 0.05);
    color: rgba(0, 0, 0, 0.87);
    letter-spacing: 3px;

    &:hover,
    &:focus,
    &:focus-visible {
      border-bottom-color: transparent;
      outline: none;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

[hidden] {
  display: none !important;
}

input:read-only {
  color: #6b747c;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: none !important;
  background-color: transparent !important;
  background-clip: content-box !important;
}


.pj-otp-input-list {
  max-width: 100%;
}

.MuiInput-input {
  &.pj-otp-input {
    height: 80px;
    font-size: var(--picklejar-font-size-large, 1.25rem);

    &::placeholder {
      bottom: -34px;
      position: relative;
      line-height: 1px;
      font-size: 120px;
    }

    &:focus::placeholder {
      color: transparent;
    }
  }
}
