.reports-container {
  border: solid thin $primary;
}

.stepper-container {
  padding: 16px;
  position: relative;

  .stepper-title {
    width: 100%;
    margin: 0;
    padding: 6px 0;
  }

  .stepper-body {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .stepper-description {
    font-weight: 100;
  }

  .stepper-progress-value {
    max-width: 200px;
    margin: 1em auto;
    position: relative;

    .h3 {
      margin: 0;
      font-weight: 100;
      font-size: 3rem
    }
  }

  .MuiMobileStepper-root {
    width: 100%;
    margin: 16px 0;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    background: transparent;

    &.variant-text-hidden {
      .MuiMobileStepper-dots {
        display: none;
      }
    }

    .MuiButton-root {
      color: $primary;

      &.Mui-disabled {
        color: $primary-disabled
      }
    }
  }
}
