.pj-claims-details__chat {
  .pj-claims-details__chat-content {
    padding: 1rem;
  }

  .pj-claims-details__chat-avatar-item {
    display: flex;
    flex-wrap: wrap;
    flex: auto;
  }
}

@media(max-width: $smMax) {



  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    //
    .pj-claims-details__chat {
      margin-bottom: 40px;
    }
  }
}


@media(max-width: $xsMax) {
  .pj-claims-details__chat {
    margin-bottom: 20px;

    .pj-claims-details__chat-header-list-item {
      flex-direction: column-reverse;
    }

    .chip {
      align-self: center;
    }

    .pj-claims-details__chat-avatar-item {
      min-width: 100%;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      flex: auto;
      text-align: center;
    }
  }
}
