.attachment-media-list {
  height: 10vh;
  width: 100%;
  max-height: 175px;
  min-height: 110px;
  object-fit: contain;
  overflow: hidden;
}

.attachment-media-list-item {
  height: 100% !important;
  width: 100%;
  max-height: 175px;
  min-height: 110px;
  object-fit: contain;
}

.attachment-media-list-link {
  width: 100%;
  display: block;
}
