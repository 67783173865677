.pj-full-width {
  width: 100%;
}

.pj-underline {
  text-decoration: underline;
}

.pj-fill-primary {
  fill: var(--picklejar-primary);
}

.pj-content-section-top-container{
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pj-brand-logo {
  width: clamp(60px, 10vw, 120px);
}

.pj-waves-bg {
  background-image: url("../../../images/waves-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
}

.pj-account {
  @supports not (-webkit-touch-callout: none) {
    min-height: 100vh;
  }
  padding-bottom: clamp(45px, 3vw, 80px);
  padding-top: clamp(45px, 3vw, 80px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  background-color: var(--picklejar-dark);
  color: var(--picklejar-light);
  text-align: center;

  h1 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: clamp(2.5rem, 10vw, 100px);
  }

  p:not(.MuiFormHelperText-root) {
    /* margin-bottom: 1rem;
    margin-top: 1rem;
    color: var(--picklejar-light); */
    font-size: clamp(1rem, 2vw, 25px);
  }

  .pj-fill-background {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    z-index: 0;
    opacity: 1;
    object-fit: cover;

    img {
      object-fit: cover;
    }
  }

  .country-code {
    @media (min-width: $md) {
      max-width: 110px;
    }

    .MuiAvatar-root {
      height: 32px;
      width: 32px;
      top: -5px;
    }
  }

  .MuiCheckbox-root:not(.Mui-checked) {
    path {
      fill: $primary;
    }
  }

  .terms-conditions__container {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .MuiListItem-root {
    justify-content: center;
    align-items: center;
  } */

  .welcome-logo {
    height: auto;
    width: 80%;
    max-width: 350px;
  }

  .welcome-logo-influencers {
    height: auto;
    width: 50%;
    max-width: 200px;
  }

  .loading-overlay {
    width: 100%;
    max-width: unset;
    &.pj-blur-backdrop {
      display: block;
      background: transparent;
      .MuiCircularProgress-root {
        display:none;
      }
    }
  }
}

.pj-content-section-container {
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: auto;
}

.pj-content-section-top {
  width: 100%;
  min-width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  text-align: center;

}

.pj-content-section-middle {
  width: 100%;
  flex: auto;
}

.pj-content-section-bottom {
  width: 100%;
  flex: 0 1 auto;
}

.pj-blur-backdrop {
  backdrop-filter: blur(3px);
}

.MuiCalendarPicker-root {
  .MuiPickersCalendarHeader-labelContainer {
    min-height: 60px;
  }

  .Mui-selected {
    background: var(--picklejar-gradient-primary);
  }
}

@media (min-width: $sm) {
  .Login, .VerifySms {
    .loading-overlay {
      width: 100%;
      max-width: unset;
    }
  }
}
