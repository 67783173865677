*, *::before, *::after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: Roboto, Montserrat, sans-serif, serif;
}

.App {
  min-height: -webkit-fill-available;
  display: flex;
  flex-wrap: wrap;
}

body {
  min-width: 350px;
  padding-bottom: env(safe-area-inset-bottom);
}

img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

span {
  display: inline-block;
}

.pj-h-100 {
  height: 100%;
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    min-height: 100vh;
  }
}
