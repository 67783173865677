.claim-card {

  img.background {
    left: 0;
    top: 0;
    position: absolute;
    z-index: 0;
    object-fit: cover;
  }
  .overlay {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 0;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  }

  .text-ellipsis {
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
