.image-crop-input {
  display: flex;
  text-align: left;

  .image-crop-input__container {
    flex-grow: 1;
  }

  .image-crop-input__input {
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      display: none;
    }

    .btn {
      padding: 4px;
      border-radius: 8px;
      background-color: transparentize(black, 0.5);

      i {
        font-size: 24px;

        &::before {
          color: $primary;
        }
      }
    }

    label {
      height: 2.25rem;
      width: 2.25rem;
      margin-bottom: 0;
      border-radius: 50%;
    }
  }

  .container__preview {
    display: flex;
  }

  .preview-crop__container {
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;

    h5 {
      margin-bottom: 8px;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .ReactCrop {
      max-height: 300px;
      min-height: 200px;
    }
  }

  .preview-image__container {
    margin-left: 4px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;

    h5 {
      margin-bottom: 8px;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .container__preview-image {
      max-height: 300px;
      min-height: 200px;
      flex-grow: 1;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}

.image-input__modal {
  .MuiContainer-root {
    border-radius: 8px;

    .modal-body {
      display: flex;
      flex-direction: column;

      .ReactCrop {
        display: flex;
      }

      .container__actions {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          &:first-child {
            margin-right: 4px;
          }

          &:last-child {
            margin-left: 4px;
          }
        }
      }
    }
  }
}

@media (min-width: $sm) {
  .image-input__modal {
    .MuiContainer-root {
      max-width: 600px;
    }
  }
}
