.Welcome, .Login {
  background-color: var(--picklejar-dark);
  .pj-welcome-header {
    margin-bottom: 0;
    margin-top: 0;
  }
  .pj-login-subtitle {
    margin-bottom: clamp(1rem, 2vh, 70px);
    margin-top: clamp(1rem, 2vh, 70px);
  }
}
