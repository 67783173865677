//Display Types
.pj-d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.v-hidden {
  visibility: hidden;
}

.pointer {
  cursor: pointer;
}

.pj-justify-content-center {
  justify-content: center;
}
