.UserProfile {

  .react-select__menu {
    color: var(--picklejar-text-color-dark);
  }

  .MuiFormControlLabel-root .MuiTypography-root,
  .MuiFormLabel-root,
  .MuiInputBase-root {
    color: white;

    input {
      color: #eaeaea;
    }
  }

  .welcome-logo {
    height: auto;
    width: 80%;
    max-width: 350px;
  }

  .welcome-logo-influencers {
    height: auto;
    width: 50%;
    max-width: 200px;
  }
}
