.pj-phone-input {
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 100;
  font-size: 24px;
  letter-spacing: .15rem;

  .MuiAvatar-root {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 16px;
    cursor: pointer;
  }

  .MuiInput-input {
    margin-right: 16px;
  }
}

@media (min-width: $sm) {
  .pj-phone-input__modal {
    .MuiContainer-root {
      max-width: 400px;
    }
  }
}
