@import "variables";
@import "globals";
@import "app";
@import "templates";
@import "layouts";
@import "components";
@import "forms";

.Claims {
  width: 100%;
  position: relative;
  color: var(--picklejar-text-color-dark, $text-color-dark);
  text-align: left;
  @import "components/index";
}

.ClaimsDetails {
  @supports not (-webkit-touch-callout: none) {
    /* CSS for other than iOS devices */
    min-height: calc(100vh - 118px);
  }

  .pj-content-wrapper {
    flex-grow: 0;
  }
}
